.scene {
    /* border: 1px solid #2a2a2a;
    margin: 10px;
    display: grid;
    overflow: hidden; */
}

ul.marquee {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.marquee {
    display: grid;
    grid-gap: 0;
    grid-template-columns: repeat(var(--count), max-content);
}

.marquee_item {
    display: flex;
    justify-content: center;
    padding: 0 calc(var(--item-gap) * 0.5);
    translate: var(--start) 0%;
    animation: slide var(--duration) var(--delay) infinite linear;
}

@keyframes slide {
    0% {
        translate: var(--start) 0%;
    }

    100% {
        translate: var(--end) 0%;
    }
}