/* Helpers */
.static,
.nostatic {
    background: {
        attachment: scroll;
        position: center;
        repeat: no-repeat;
        size: cover;
    }
}

@media (min-width: 992px) {
    .static {
        background-attachment: fixed;
    }
}

.container {
    width: var(--container-width);
}

.row-va {
    @media (min-width: 768px) {
        display: flex;
        align-items: center;
    }
}

.row-vh {
    justify-content: center;
}

.img-fluid {
    width: 100%;
}

body.modal-open {
    overflow-y: auto !important;
    padding-right: 0 !important;
}

.d-block {
    @include md {
        display: block;
    }
}

.gap {
    &-10 {
        gap: 10px;
    }
    &-20 {
        gap: 20px;
    }
}

.wow {
    visibility: hidden;
    animation-fill-mode: both;
}

*:focus {
    outline: none !important;
}

figure {
    margin: 0;
}

.arrow {
    font-family: Inter, sans-serif;
    display: inline-block;
    transform: rotate(-45deg);
}

.grid {
    display: grid;
    grid-template-columns: 100%;
    gap: 20px;

    @include md {
        &-2 {
            grid-template-columns: repeat(2, 1fr);
        }
        &-3 {
            grid-template-columns: repeat(3, 1fr);
        }
    }
}

.button {
    color: var(--color-dark);
    background: linear-gradient(to bottom, var(--color-yellow), #a7af3a);
    padding: 12px 22px 10px;
    border-radius: 7px;
    text-decoration: none;
    border: 0;
    box-shadow: 0 17px 50px -3px rgba(215, 277, 59, 0.4);
    letter-spacing: calculateRem(-0.25px);
    font-weight: 600;
    transition: var(--default-transition);
    &:hover {
        color: var(--color-dark);
    }

    &--small {
        padding: 9px 22px 8px;
        font-size: 15px;
    }

    &--bordered {
        border-radius: 9px;
        background: transparent;
        color: var(--color-yellow);
        border: 2px solid var(--color-yellow);
        box-shadow: none;

        &:hover {
            color: var(--color-yellow);
        }
    }
}

.color-yellow {
    background: linear-gradient(to bottom, var(--color-yellow), #a7af3a);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    // color: var(--color-yellow);
}

.section {
    padding: 50px 0;
}

.title {
    gap: 10px;

    .subtitle {
        color: var(--color-greydark);
    }

    .lead {
        color: var(--color-greydark);
        font-size: var(--font-size-text-large);
    }

    .h-title {
        margin: 0;
    }

    @include md {
        // display: grid;
        // grid-template-columns: 1fr 1fr;
        // gap: 40px;
    }
    @include md {
        max-width: 650px;
    }
}

.title-gradient {
    background: linear-gradient(to bottom, #fff, #999);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;

    @include lg {
        font-size: var(--font-size-title-xl);
    }
}

html body {
    .swiper {
        padding-top: 55px;
    }

    .swiper-button {
        &-prev,
        &-next {
            width: 60px;
            height: 40px;
            background-color: rgba(0, 0, 0, 0.5);
            backdrop-filter: blur(10px);
            outline: 1px solid rgba(255, 255, 255, 0.15);
            transition: var(--default-transition);

            position: absolute;
            top: 22px;

            &:hover {
                background-color: rgba(0, 0, 0, 0.75);
            }

            &:after {
                font-size: 20px;
                color: var(--color-yellow);
            }
        }
        &-prev {
            border-radius: 100px 0 0 100px;
        }
        &-next {
            border-radius: 0 100px 100px 0;
            border-left: 0;
            right: inherit;
            left: 70px;
        }
    }
}

/* Body animation */
body.highlight {
    .about-us {
        background-color: #222;
    }
}

/* News section */
.news {
    &__content {
        padding: 20px;
        text-align: center;

        p {
            @include font-size(17px);
            margin: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 10px;

            span {
                font-weight: 600;
            }

            &.small {
                @include font-size(14px);
                color: var(--color-greydark);
                margin: 0;
            }
        }

        .button {
            padding: 10px 16px;
            font-weight: 600;
            @include font-size(13px);
            transition: transform 0.2s ease;

            &:hover {
                transform: translateY(-1px);
            }
        }
    }

    @include mobile {
        background-color: #222;
        padding: 30px 0;

        p {
            @include font-size(22px);

            .color-yellow {
                @include font-size(32px);
                font-weight: 700;
            }
        }

        .button {
            @include font-size(18px);
            margin: 15px 0;
            padding: 12px 24px;
        }
    }

    @include md {
        &__content p {
            flex-direction: row;
            justify-content: center;
            gap: 15px;
        }
    }
}

.terms-container {
    padding-top: 150px;

    .title {
        @include md {
            max-width: 100%;
        }
    }

    & > .container {
        color: #ddd;

        @include md {
            max-width: 750px;
        }
    }

    nav {
        padding-bottom: 30px;
        position: relative;
        z-index: 1;

        .nav {
            position: relative;
            top: 0;
            left: 0;
            width: 100%;
            max-width: 100%;

            background-color: transparent;
            border: 0;
            border-radius: 0;
            padding: 0;
            margin: 0;
            display: flex;
            justify-content: center;
            gap: 20px;

            button {
                border: 0;
                border: 0;
                border-radius: 6px;

                &.nav-link {
                    color: var(--color-yellow);
                    &.active {
                        background-color: var(--color-yellow);
                        color: var(--color-dark);
                        font-weight: bold;
                    }
                }
            }
        }
    }
    .h3-title {
        font-size: var(--font-size-title-h2);
        letter-spacing: 0;
        margin-top: 50px;
    }
    .tab-content {
        a {
            color: var(--color-yellow);
            text-decoration: underline;
        }
    }
}

.cta {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    margin-top: 50px;

    .tip {
        @include font-size(12px);
        background-color: var(--color-yellow);
        color: var(--color-dark);
        padding: 5px 10px;
        border-radius: 100px;
    }

    img {
        border-radius: 50%;
        width: 50px;
    }

    .text {
        font-weight: normal;
        font-size: var(--font-size-text-normal);
        max-width: 500px;
        color: var(--color-greydark);
    }
    & div > span {
        line-height: 1.25;
    }

    @include mobile {
        justify-content: center;
    }
    @include md {
        & div > span {
            font-size: var(--font-size-title-h4);
            font-weight: bold;
        }
    }
}
