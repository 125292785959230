body.start-page {
    .nav {
        .button {
            display: none;
        }
        @media (min-width: 1200px) {
            max-width: var(--container-width);
        }
    }
}

.leadCapture {
    padding-top: 100px;
    min-height: 650px;
    position: relative;
    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        width: 100%;
        height: 400px;

        z-index: -1;
        background-image: url(../Home/hero-background-2.svg);
        background-size: cover;
        background-position: center top;
    }

    &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: linear-gradient(to top, #000 0%, transparent 100%);
        width: 100%;
        height: 400px;
        z-index: -1;
    }

    &__content {
        display: flex;
        align-items: flex-start;
        gap: 20px;

        @media (max-width: 767px) {
            flex-direction: column;
            .items {
                margin-bottom: 20px;
            }
            ul {
                display: none !important;
            }
        }

        .leadCapture__content__info {
            flex: 1;
            min-width: 0;
            width: 100%;

            .supported-by {
                margin-bottom: 10px;
            }
            .items {
                display: flex;
                gap: 10px;
                .item {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    &:first-child {
                        svg {
                            color: #a855f7;
                        }
                    }
                    &:nth-child(2) {
                        svg {
                            color: #22c55e;
                        }
                    }
                    &:nth-child(3) {
                        svg {
                            color: #eab308;
                        }
                    }
                }
            }
            ul {
                margin: 0;
                padding: 0;
                list-style: none;
                display: flex;
                flex-direction: column;
                gap: 10px;

                li {
                    display: flex;
                    align-items: center;
                    gap: 10px;

                    svg {
                        width: 20px;
                        height: 20px;
                        color: #22c55e;
                    }
                }
            }
        }

        .lead-capture-form {
            flex: 1;
            min-width: 0;
            width: 100%;
        }
    }
    .lead-capture-form {
        // background: linear-gradient(20deg, rgba(3, 3, 3, 0.8) 57%, rgba(47, 47, 47, 0.8) 100%);
        background: linear-gradient(20deg, rgba(3, 3, 3, 0.8) 57%, rgba(21, 0, 200, 0.8) 100%);
        backdrop-filter: blur(5px);
        &:before {
            display: none;
        }
    }
}
