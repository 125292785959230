.landing {
    --spacing: 30px;
    --border-radius: 12px;
    --gradient-bg: radial-gradient(
            100.56% 112.16% at 98.17% -25.21%,
            rgba(126, 133, 137, 0.24) 0%,
            rgba(0, 0, 0, 0) 100%
        ),
        rgba(30, 35, 42, 0.81);

    @include md {
        --container-width: 1000px;
    }

    h1,
    h2,
    h3 {
        letter-spacing: calculateRem(-1.5px);
        font-weight: bold;
    }

    .container {
        @include md {
            max-width: 900px;
        }
    }
    .button {
        font-weight: bold;
        font-size: var(--font-size-text-large);
        margin-bottom: 10px;
        img {
            width: 35px;
            height: auto;
            border-radius: 50%;
            margin-right: 10px;
        }
    }

    .person-img {
        width: 35px;
    }

    .projects,
    .fix,
    .info,
    .data,
    .pricing {
        padding: 50px 0;
    }

    .about-us {
        background-position: center top;
        min-height: 85vh;
        position: relative;

        &::before {
            display: block !important;
            @include opacitybefore(1);
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 95%);
        }

        & > .container {
            position: relative;
            z-index: 1;
            @include md {
                // max-width: 800px;
            }
            @include lg {
                max-width: 1100px;
            }
        }

        .person-img {
            width: 40px;
            @include md {
                width: 70px;
            }
        }

        .title {
            text-align: center;
            align-items: center;

            @include md {
                max-width: 100%;
            }
        }

        .actions {
            display: flex;
            align-content: center;
            gap: 20px;
        }

        &__description {
            text-align: center;
            strong {
                display: block;

                max-width: 650px;
                margin: 0 auto;
                margin-top: 20px;
            }
        }
        &:before {
            display: none;
        }
    }

    .logos-section {
        padding-top: 10px;
    }

    .info,
    .projects {
        .title-gradient {
            font-size: var(--font-size-title-h1);
        }
    }

    .info {
        padding-top: 100px;
    }

    .projects__grid .text .info .button {
        width: fit-content;
    }

    .projects .project-item {
        .services,
        .info .tags {
            display: none;
        }
    }

    .fix {
        & > .container {
            @include lg {
                margin: auto;
                max-width: 1200px;
            }
        }

        .cards {
            .info-card {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: var(--spacing);
                margin-bottom: var(--spacing);
                padding: var(--spacing);
                border-width: 1px;

                @include md {
                    flex-direction: row;
                }

                figure {
                    border-radius: var(--border-radius-large);
                    aspect-ratio: 1/1.2;
                }

                figure,
                & > div {
                    flex: 1;
                }

                p {
                    margin-bottom: 1rem;
                    &:last-of-type {
                        margin: 0;
                    }
                }

                &.even {
                    @include md {
                        flex-direction: row-reverse;
                    }
                }

                &__content {
                    gap: var(--spacing);

                    h3 {
                        font-size: var(--font-size-title-h2);
                        letter-spacing: calculateRem(-0.75px);
                        position: relative;
                        width: fit-content;
                        margin-bottom: 20px;

                        &::before {
                            content: '';
                            width: 40px;
                            height: 3px;
                            display: block;
                            background-color: var(--color-yellow);
                            position: absolute;
                            bottom: -15px;
                            left: 0;
                        }
                    }
                }
            }
        }
    }

    .data {
        &-row {
            .col-12 {
                .data__item {
                    margin-bottom: 30px;
                }
                &:last-of-type .data__item {
                    margin-bottom: 0;
                }
            }
        }

        .title {
            text-align: center;
            margin-bottom: 50px;
            .title-gradient {
                font-size: var(--font-size-title-h1);
            }
        }

        &__item {
            border: 1px solid #384d59;
            background: var(--gradient-bg);
            border-radius: var(--border-radius);
            padding: 30px;
            position: relative;
            overflow: hidden;
            @include md {
                min-height: 300px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }

            h3,
            p {
                position: relative;
                z-index: 1;
                margin: 0;
            }

            h3 {
                font-size: var(--font-size-title-h1);
            }
            &:after {
                content: '';
                position: absolute;
                inset: 0;
                background-image: radial-gradient(circle, var(--color2, #e16e6c) 60%, #1e2229 50%);
                background-size: 150%;
                background-position: 102px 58px;
                background-repeat: no-repeat;
                filter: blur(40px);
                opacity: 0.65;
            }
        }

        .col-12.col-md:nth-child(2) .data__item:after {
            --color2: #ac6ce1;
        }

        .col-12.col-md:nth-child(3) .data__item:after {
            --color2: #0e8621;
        }
    }

    .pricing {
        .pricing__footer {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 20px;
            max-width: 600px;
            margin: 0 auto;

            .small {
                text-align: center;
                color: var(--color-greydark);
                font-size: var(--font-size-text-small);

                a {
                    color: var(--color-yellow);
                    display: block;
                }
            }
        }

        .title {
            margin: 0 auto 30px;
            text-align: center;

            .title-gradient {
                font-size: var(--font-size-title-h1);
                margin-bottom: 20px;
            }
        }

        .price {
            gap: 10px;

            span {
                line-height: 1;
            }

            .old {
                color: var(--color-greydark);
                text-decoration: line-through;
                font-size: var(--font-size-title-h2);
            }

            .new {
                color: var(--color-yellow);
                letter-spacing: calculateRem(-1px);
                font-weight: bold;
                text-shadow: 0 0 50px rgba(255, 255, 0, 0.86);
                font-size: var(--font-size-title-display);
            }
        }

        .table {
            max-width: 500px;
            margin: 50px auto;

            ul {
                list-style: none;
                border: 1px solid #384d59;
                background: var(--gradient-bg);
                border-radius: var(--border-radius);
                padding: 15px;
                position: relative;
                margin: 0 auto 20px;

                li {
                    font-weight: normal;
                    padding: 8px 0;
                    border-bottom: 1px solid rgba(255, 255, 255, 0.15);

                    &.h {
                        color: var(--color-yellow);
                        font-weight: bold;
                    }

                    &:last-child {
                        border-bottom: none;
                    }
                }
            }
        }

        .payments {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            gap: 20px;
        }
    }

    .faqs .title .title-gradient,
    .warranty .title-gradient {
        font-size: var(--font-size-title-h1);
    }

    .faqs .title .title-gradient .color-yellow {
        display: block;
    }

    .faqs .accordion-body .highlight {
        color: var(--color-yellow);
    }

    .warranty {
        .title {
            text-align: center;
            margin: auto;
        }

        img {
            display: block;
            width: 100%;
            max-width: 500px;
            margin: auto;
            height: auto;
        }

        .lead,
        .small {
            font-size: var(--font-size-text-large);
            color: var(--color-greydark);
            text-align: center;
        }

        .small {
            font-size: var(--font-size-text-small);
        }

        &__content {
            border: 1px solid #384d59;
            background: var(--gradient-bg);
            border-radius: var(--border-radius);
            padding: 30px 15px;
            position: relative;
        }
    }

    .call .actions {
        flex-direction: column;

        .small {
            font-size: var(--font-size-text-normal);
            margin: 0;
            order: 2;
            text-align: center;

            @include lg {
                max-width: 500px;
                margin: auto;
            }

            strong {
                font-size: var(--font-size-text-large);
                display: block;
            }
        }

        .button {
            order: 3;
            max-width: fit-content;
        }

        .supported-by {
            order: 1;
        }
    }
    .call {
        .title-gradient {
            font-size: var(--font-size-title-h1);
        }
    }
}
