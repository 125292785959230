body.start-page {
    .footer {
        a.button.button--bordered {
            display: none;
        }
    }
}

.footer {
    color: var(--color-greydark);
    padding-bottom: 50px;
    background: linear-gradient(to bottom, #010101, #1c1938);

    hr {
        margin-bottom: 50px;
    }

    &__container {
        @include mobile {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 20px;
            text-align: center;
        }

        @include md {
            display: flex;
            justify-content: space-between;

            .logo {
                max-width: 400px;
                width: 100%;
                margin: 0;
            }
            .sm {
                width: fit-content;
            }
        }
    }
    .logo {
        img {
            max-width: 150px;
            margin-bottom: 15px;
        }

        p {
            margin: 0;
            font-size: 14px;
            .d-block {
                display: block;
                font-size: 12px;
                margin-top: 8px;
            }
        }
    }
    .sm {
        display: inline-flex;
        align-items: center;
        gap: 30px;
        margin: 0;
        a {
            color: white;
            font-size: 25px;
            transition: color 0.5s ease;
            &:hover {
                color: var(--yellow);
            }
            &.button {
                @include font-size(13px);
                padding: 7px 10px;
                &:hover {
                    color: var(--yellow);
                }
            }
        }
    }
}
.sm-tos {
    display: flex;
    flex-direction: column;
    align-items: center;
    @include sm {
        align-items: flex-end;
    }
}
.tos {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding-top: 10px;

    a {
        color: var(--color-greydark);
        @include font-size(13px);
        transition: var(--default-transition);

        @include md {
            text-align: right;
        }
        &:hover {
            color: var(--color-yellow);
            text-decoration: underline;
        }
    }
}
