.hero-home {
    padding: 120px 0 30px;
    position: relative;

    &:before {
        content: '';
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        background: linear-gradient(to top, #000 0%, transparent 100%);
        width: 100%;
        height: 400px;
        z-index: 0;
    }

    @include lg {
        padding: 120px 0 50px;
    }

    .supported-by {
        margin-bottom: 0;
    }
    .container {
        position: relative;
        z-index: 10;
    }
    .title {
        gap: 10px;
        align-items: center;
        text-align: center;
        max-width: 100%;

        .h-title {
            font-size: var(--font-size-title-display);
            line-height: 110%;
            @include lg {
                line-height: 1;
            }
        }
        .lead {
            max-width: 700px;
            margin: 0 auto;
        }

        @include mobile {
            .img {
                display: inline-block;
                width: 40px;
            }
            .h-title {
                @include font-size(45px);
            }
        }

        @include lg {
            .h-title {
                @include font-size(70px);
            }
        }
        .actions {
            .button {
                display: flex;
                align-items: center;
                gap: 10px;

                @include font-size(18px);
                @include lg {
                    @include font-size(20px);
                }

                img {
                    border-radius: 100px;
                    width: 35px;
                    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25);
                }
            }
        }
    }
    @include mobile {
        background-size: 1400px;
        background-position: center top;
    }
    .small {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        margin: 0;
        div {
            &:nth-child(1) {
                svg {
                    color: #a855f7;
                }
            }
            &:nth-child(2) {
                svg {
                    color: #22c55e;
                }
            }
            &:nth-child(3) {
                svg {
                    color: var(--color-yellow);
                }
            }
        }
    }
}
.about-us {
    padding: 100px 0;
    background-color: #222;
    background: linear-gradient(0deg, rgb(3, 3, 3) 0%, rgb(3, 3, 3) 50%, rgb(20, 20, 20) 100%);

    p {
        color: var(--color-greydark);
        font-size: var(--font-size-title-h4);
        &:not(.about-us__description) {
            font-size: 97%;
            color: white;
            @include md {
                font-size: 95%;
                max-width: 800px;
            }
        }
    }
}
.logos-section {
    padding-top: 20px;

    & > .container {
        @include md {
            width: 100%;
            max-width: 1500px;
        }
    }

    .scene {
        position: relative;
        overflow: hidden;

        img {
            filter: contrast(0);
            height: 80px;
            width: auto;
        }

        &:before,
        &:after {
            content: '';
            height: 100%;
            width: 200px;
            position: absolute;
            z-index: 10;
            top: 0;
        }

        &:before {
            left: 0;
            background: rgb(0, 0, 0);
            background: linear-gradient(90deg, #010101 0%, rgba(255, 255, 255, 0) 50%);
        }
        &:after {
            right: 0;
            background: rgb(255, 255, 255);
            background: linear-gradient(90deg, rgba(255, 255, 255, 0) 50%, #010101 100%);
        }
    }
}

.info {
    padding: 100px 0;

    .container .row:nth-child(2) {
        margin-top: 50px;
    }

    .grid-item {
        text-align: center;
        gap: 10px;
        background: rgb(3, 3, 3);
        background: linear-gradient(20deg, rgba(3, 3, 3, 1) 57%, rgba(47, 47, 47, 1) 100%);
        position: relative;

        background-clip: padding-box;
        border: solid 1px transparent; /* !importanté */

        // border-style: solid;
        // border-width: 1px;
        // border-image: radial-gradient(rgb(174, 174, 174), rgb(40, 40, 40)) 1;

        padding: 20px;
        border-radius: var(--border-radius-large);

        &:nth-child(1) {
            svg {
                width: 60px;
                height: 60px;
                color: #3b82f6; // Blue for mobile icon
            }
        }

        &:nth-child(2) {
            svg {
                width: 60px;
                height: 60px;
                color: #f59e0b; // Orange for search icon
            }
        }

        &:nth-child(3) {
            svg {
                width: 60px;
                height: 60px;
                color: #22c55e; // Green for edit icon
            }
        }

        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            margin: -1px; /* !importanté */
            border-radius: inherit; /* !importanté */
            background: radial-gradient(rgb(174, 174, 174), rgb(40, 40, 40));
        }

        @include md {
            padding: 50px 20px;
        }

        h3 {
            letter-spacing: 0;
        }
        h3,
        p {
            margin: 0;
        }
        p {
            color: var(--color-greydark);
        }
    }
}
.fix {
    background: linear-gradient(to bottom, #010101, #151515, #010101);
    padding: 0 0 100px;

    #girl-2 {
        @include lg {
            width: 50px;
        }
    }

    & > .container {
        position: relative;
        z-index: 100;
        @include lg {
            /*
            margin: 0;
            margin-left: calc((100% - var(--container-width)) / 2);
            max-width: calc(((100% - var(--container-width)) / 2) + var(--container-width));
            width: 100%;
            */
        }
    }
    .info {
        max-width: 650px;
        padding: 0 0 50px;
    }

    .title {
        display: flex;
        flex-direction: column;
        margin: 0;
        max-width: 100%;
        text-align: left;
        align-items: flex-start;
        flex-direction: column;
        gap: 10px;

        .title-gradient {
            span {
                @include md {
                    display: block;
                }
            }
        }

        .lead {
            width: 100%;
        }
    }
    .cards {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    .slide-content {
        border: 0;
        margin-top: 2px;
        border-radius: var(--border-radius-large);
        color: var(--color-greydark);
        gap: 20px;
        position: relative;
        padding: 15px;
        background-clip: padding-box;
        border: solid 1px transparent; /* !importanté */
        transition: var(--default-transition);
        background: radial-gradient(
                100.56% 112.16% at 98.17% -25.21%,
                rgba(126, 133, 137, 0.24) 0%,
                rgba(0, 0, 0, 0) 100%
            ),
            rgba(10, 10, 10, 0.81);

        &.highlighted {
            background: radial-gradient(
                    100.56% 112.16% at 98.17% -25.21%,
                    rgba(126, 133, 137, 0.24) 0%,
                    rgba(0, 0, 0, 0) 100%
                ),
                rgba(64, 70, 80, 0.81);
            &:before {
                background: radial-gradient(rgb(190, 190, 190), rgb(100, 100, 100));
            }
        }

        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            margin: -2px; /* !importanté */
            border-radius: inherit; /* !importanté */
            background: radial-gradient(rgb(190, 190, 190), rgb(50, 50, 50));
        }

        @include md {
            display: grid;
            grid-template-columns: 1fr 1fr;
            align-items: center;
            gap: 20px;
        }
        @include lg {
            grid-template-columns: auto 55%;
            &:nth-of-type(odd) {
            }
            &:nth-of-type(even) {
                grid-template-columns: 55% auto;
                figure {
                    order: 2;
                }
            }
        }

        .pretitle {
            background-color: #444;
            display: inline-block;
            width: fit-content;
            padding: 5px 10px;
            border-radius: 5px;
            color: var(--color-yellow);
            @include font-size(14px);
        }

        h3 {
            color: white;
            margin: 0;
            letter-spacing: calculateRem(-0.5px);
        }
        p {
            color: var(--color-greysoft);
            margin: 0 0 1rem;
            @include md {
                // font-size: var(--font-size-text-small);
            }
            &:last-of-type {
                margin-bottom: 0;
            }
        }
        a {
            color: var(--color-yellow);
        }

        figure {
            border-radius: var(--border-radius-small);
            width: 100%;
            aspect-ratio: 16/9;
            position: relative;
            margin-bottom: 20px;

            @include md {
                margin-bottom: 0;
            }

            @include lg {
                aspect-ratio: 1/1;
            }

            &:before {
                content: '';
                width: 100%;
                height: 100%;
                top: 0;
                position: absolute;
                background: inherit;
                filter: blur(20px);
                z-index: -1;
            }

            box-shadow:
                0.6px 0.8px 1.1px hsl(var(--shadow-color) / 0.34),
                3.7px 4.6px 6.6px -0.4px hsl(var(--shadow-color) / 0.34),
                7px 8.7px 12.6px -0.7px hsl(var(--shadow-color) / 0.34),
                11.5px 14.2px 20.6px -1.1px hsl(var(--shadow-color) / 0.34),
                18.3px 22.8px 32.9px -1.4px hsl(var(--shadow-color) / 0.34),
                28.7px 35.6px 51.4px -1.8px hsl(var(--shadow-color) / 0.34),
                43.6px 54.1px 78.2px -2.1px hsl(var(--shadow-color) / 0.34),
                64.2px 79.6px 115px -2.5px hsl(var(--shadow-color) / 0.34);
        }
    }
}

.projects {
    padding: 0 0 100px;

    .title {
        @include lg {
            max-width: 750px;
        }
    }

    ul {
        margin: 0;
        margin: 0 0 100px;
        padding: 0;
        list-style: none;
        display: flex;
        gap: 10px;

        li {
            display: flex;
            align-items: start;
            gap: 10px;

            &:nth-child(1) svg {
                width: 20px;
                height: 20px;
                color: #22c55e; // Green for conversion icon
            }

            &:nth-child(2) svg {
                width: 20px;
                height: 20px;
                color: #3b82f6; // Blue for mobile icon
            }

            &:nth-child(3) svg {
                width: 20px;
                height: 20px;
                color: #f59e0b; // Orange for search icon
            }

            &:nth-child(4) svg {
                width: 20px;
                height: 20px;
                color: #8b5cf6; // Purple for edit icon
            }
        }
    }

    &__grid {
        display: grid;
        gap: 20px;
        grid-template-columns: 100%;

        .text {
            .info {
                padding: 0;
                position: sticky;
                top: 100px;
                gap: 10px;
            }
            .supported-by {
                width: fit-content;
                margin: 0;
                @include mobile {
                    display: none;
                }
            }
            h3 {
                margin: 0;
                letter-spacing: 0;
                span {
                    color: var(--color-yellow);
                }
            }
            p {
                color: var(--color-greydark);
                margin: 0;
            }
        }

        @include md {
            grid-template-columns: 40% 1fr;
            gap: 50px;
        }
    }

    .projects-list {
        gap: 30px;
    }
    .project-item {
        gap: 20px;
        padding: 15px;
        position: relative;
        border-radius: var(--border-radius-large);
        background: radial-gradient(
                100.56% 112.16% at 98.17% -25.21%,
                rgba(126, 133, 137, 0.24) 0%,
                rgba(0, 0, 0, 0) 100%
            ),
            rgba(30, 35, 42, 0.81);

        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            margin: -1px; /* !importanté */
            border-radius: inherit; /* !importanté */
            background: radial-gradient(rgb(190, 190, 190), rgb(100, 100, 100));
        }
        .tag {
            background-color: var(--color-yellow);
            font-weight: 500;
            color: black;
            padding: 3px 20px 2px;
            border-radius: 4px;
            @include font-size(14px);

            position: absolute;
            bottom: 10px;
            right: 10px;
            z-index: 1;
            box-shadow: 0 10px 40px -5px rgba(0, 0, 0, 0.5);
        }
        &__info {
            position: relative;
        }
        .info {
            padding: 0;
            align-items: flex-start;
            flex-direction: column;
            gap: 10px;

            @include md {
                flex-direction: row;
            }
        }

        h3 {
            @include font-size(23px);
            color: var(--color-greysoft);
            margin: 0;
            width: 100%;
            flex: 1;

            @include md {
                // width: 52%;
            }
        }

        .services {
            @include font-size(16px);
            font-weight: normal;
            color: var(--color-greydark);
        }

        .collab {
            color: var(--color-greydark);
            @include font-size(14px);
            a {
                color: var(--color-yellow);
            }
        }
        figure {
            background-position: center top;
            aspect-ratio: 16/9;
            --shadow-color: 0deg 0% 15%;
            position: relative;

            box-shadow:
                0.6px 0.8px 1.1px hsl(var(--shadow-color) / 0.34),
                3.7px 4.6px 6.6px -0.4px hsl(var(--shadow-color) / 0.34),
                7px 8.7px 12.6px -0.7px hsl(var(--shadow-color) / 0.34),
                11.5px 14.2px 20.6px -1.1px hsl(var(--shadow-color) / 0.34),
                18.3px 22.8px 32.9px -1.4px hsl(var(--shadow-color) / 0.34),
                28.7px 35.6px 51.4px -1.8px hsl(var(--shadow-color) / 0.34),
                43.6px 54.1px 78.2px -2.1px hsl(var(--shadow-color) / 0.34),
                64.2px 79.6px 115px -2.5px hsl(var(--shadow-color) / 0.34);
            border-radius: var(--border-radius-large);
        }
        .button {
            width: fit-content;
        }
    }
    .projectActions {
        display: flex;
        flex-direction: column-reverse;
        gap: 10px;
    }
}

.tech {
    padding: 100px 0;
    position: relative;
    border-top: 1px solid #48454D;
    border-bottom: 1px solid #45365B;
    background: radial-gradient(at bottom right, #422a65, #030303);

    &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-size: contain;
        background-position: center right;
        background-repeat: no-repeat;
        background-image: url(../../components/Brands/tech-background.png);
        width: 100%;
        height: 100%;
        z-index: 0;
        @include mobile {
            background-image: none !important;
        }
    }
    @include mobile {
        padding: 50px 0;
    }
    .title {
        @include md {
            max-width: 500px;
        }
    }

    .brands {
        display: flex;
        gap: 5px;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 30px;

        @include mobile {
            justify-content: center;
            gap: 10px;
        }

        @include md {
            gap: 10px;
        }
    }
    .brand {
        display: flex;
        flex-direction: column;
        align-items: center;

        figure {
            background: rgb(255, 255, 255);
            background: radial-gradient(circle, rgba(255, 255, 255, 1) 30%, rgba(200, 200, 200, 1) 100%);
            border-radius: 100px;
            width: 60px;
            height: 60px;
            display: grid;
            place-items: center;
        }
        figcaption {
            display: block;
            margin-top: 5px;
            text-align: center;
            @include font-size(12px);
            color: var(--color-greydark);
        }
        img {
            width: 40px;
            height: auto;
        }
    }
}

.testimonials {
    padding: 100px 0;

    & > .container {
        @include lg {
            width: 100%;
            max-width: 1140px;
        }
    }

    .title {
        position: relative;
    }
    .testimonials__container {
        --spacing: 15px;

        @include md {
            column-count: 3;
            column-gap: var(--spacing);
        }

        .testimonial {
            margin-bottom: var(--spacing);
        }
    }
    .testimonial {
        page-break-inside: avoid-column;
        break-inside: avoid-column;
        -webkit-column-break-inside: avoid-column;

        justify-content: center;
        align-items: center;
        text-align: center;
        gap: 30px;
        position: relative;

        padding: 20px;

        .rating {
            display: flex;
            gap: 0px;
            margin-top: 0px;

            svg {
                width: 15px;
                height: 15px;
            }
        }

        &.active {
            .testimonial__quote {
                opacity: 1;
            }
        }

        @include lg {
            text-align: left;
            padding: 30px 20px;
        }

        &:hover {
            .testimonial__quote {
                opacity: 1;
            }
        }

        &__quote {
            margin: 0;
            color: var(--color-greydark);
            @include font-size(15px);
            font-weight: normal;
            position: relative;
            transition: var(--default-transition);

            &:before,
            &:after {
                transition: var(--default-transition);
            }

            p {
                @include font-size(16px);
            }
        }
        &__name {
            margin: 0;
            color: white;
            @include font-size(13px);
        }
        &__position {
            @include font-size(12px);
            margin: 0;
            color: white;
            display: none;
        }

        &__image {
            text-align: left;
            gap: 15px;
            width: 100%;
            max-width: 350px;
            margin-top: 30px;

            img {
                width: 50px;
                aspect-ratio: 1/1;
                border-radius: 100px;
            }
        }
    }
}
section.services {
    padding: 100px 0;
    background: linear-gradient(to bottom, #020202, #1c1834, #020202);
    // background-color: #151515;

    .title {
        text-align: center;
        margin-bottom: 50px;
        display: flex;
        flex-direction: column;

        @include md {
            max-width: 850px;
            align-items: center;
            margin: 0 auto 50px;
        }
    }
    .side {
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
        justify-content: center;
        .item {
            color: var(--color-greysoft);
            padding: 10px 30px 8px;
            border-radius: 50px;
            width: fit-content;
            border: 1px solid #43435d;
            background: linear-gradient(to right, #2d2a3f, #2d2a3f);
            display: inline-block;
        }
    }
    .middle {
        margin: 50px 0;
        text-align: center;
        .title-gradient {
            font-size: var(--font-size-title-h2);
        }
    }
}

.faqs {
    padding: 100px 0;
    position: relative;

    .title {
        margin-bottom: 50px;
    }

    .container {
        position: relative;
        z-index: 10;
    }
    &__container {
        @include md {
            grid-template-columns: 55% 1fr;
            align-items: start;
        }
        @include lg {
            grid-template-columns: 60% 1fr;
        }
    }
    &__call {
        position: relative;
        border-radius: var(--border-radius-large);
        padding: 20px;
        gap: 20px;
        align-items: flex-start;
        background: rgba(3, 3, 3, 0.3);
        background: linear-gradient(20deg, rgba(3, 3, 3, 0.3) 57%, rgba(100, 100, 100, 0.3) 100%);
        backdrop-filter: blur(10px);
        border: 1px solid rgba(255, 255, 255, 0.15);

        .title-gradient {
            font-size: var(--font-size-title-h3);
        }

        h3,
        p {
            margin: 0;
        }
        .button {
            display: inline-block;
        }

        img {
            max-width: 80px;
            border-radius: 50%;
            @include md {
                max-width: 90px;
            }
        }
    }

    .accordion {
        background-color: transparent;
        border: 0;
        border-radius: 0;

        // display: flex;
        // flex-direction: column;
        // gap: 10px;

        .accordion-item {
            background-color: transparent;
            border: 0;
            border-radius: 0;
            &:last-of-type {
                .accordion-button {
                    border-bottom: 0;
                }
            }
        }
        .accordion-header {
            .accordion-button {
                background-color: transparent;
                border: 0;
                border-radius: var(--border-radius-small);
                color: white;
                font-size: var(--font-size-title-h4);
                box-shadow: none;
                padding-left: 20px;
                padding-right: 20px;

                &[aria-expanded='true'] {
                    background-color: var(--color-yellow);
                    border-bottom: 1px solid var(--color-yellow);
                    color: black;

                    .faq-title > span {
                        color: black;
                        font-weight: bold;
                    }
                }

                .faq-title > span {
                    color: var(--color-yellow);
                    font-weight: bold;
                }
            }
        }
        .accordion-body {
            color: var(--color-greysoft);
            background-color: rgba(35, 35, 43, 0.75);
            backdrop-filter: blur(20px);
            padding: 20px;
            border-radius: var(--border-radius-small);
            margin: 20px 0;
            a {
                color: var(--color-yellow);
                text-decoration: underline;
            }
        }
    }
    &::before,
    &::after {
        content: '';
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
    }

    &::before {
        background: linear-gradient(0deg, rgba(25, 25, 25, 0) 70%, #010101 95%);
    }

    &::after {
        background: linear-gradient(0deg, #010101 5%, rgba(25, 25, 25, 0) 29%);
        z-index: 1;
    }
}
.call {
    padding-bottom: 50px;
    .col-12 {
        gap: 20px;
        align-items: center;
    }
    .title {
        text-align: center;
        margin: auto;
        @include md {
            max-width: 850px;
        }
    }
    .supported-by {
        width: fit-content;
        margin: 0;
    }
    .actions {
        display: flex;
        align-items: center;
        flex-direction: column-reverse;
        gap: 20px;
        .button {
            width: 100%;
            max-width: fit-content;
            text-align: center;
            font-size: var(--font-size-text-large);
            font-weight: bold;

            img {
                border-radius: 100px;
                margin-right: 10px;
            }
        }
    }
}

.not-found {
    padding: 150px 0 100px;
    text-align: center;

    h1 {
        font-size: var(--font-size-title-xl);
        margin-bottom: 20px;
    }

    p {
        color: var(--color-greydark);
        font-size: var(--font-size-title-h4);
        margin-bottom: 30px;
    }

    .button {
        display: inline-block;
    }
}
