/* Pricing Web */

.pricingWeb {
    padding: 100px 0;
    background-color: #222;
    // background: linear-gradient(20deg, rgba(3, 3, 3, 1) 57%, rgba(47, 47, 47, 1) 100%);
    background: linear-gradient(45deg, rgba(30, 30, 30, 1) 0%, rgba(16, 20, 18, 1) 50%, rgba(20, 20, 20, 1) 100%);

    .title {
        margin-bottom: 50px;
    }

    .container {
        position: relative;
        z-index: 1000;
        &.container-large {
            max-width: 1200px;
            width: 100%;
        }
    }
    .pricing-items {
        display: flex;
        flex-direction: column;
        gap: 20px;
        @include md {
            flex-direction: row;
        }
    }
    .pricing-item {
        flex: 1;
        border-radius: 15px;

        height: fit-content;

        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        background: rgb(3, 3, 3);
        background: linear-gradient(20deg, rgba(3, 3, 3, 1) 57%, rgba(47, 47, 47, 1) 100%);
        position: relative;

        background-clip: padding-box;
        border: solid 1px transparent; /* !importanté */
        padding: 20px;
        border-radius: var(--border-radius-large);

        &.retainer {
            margin-top: 30px;

            @include lg {
                // max-width: 800px;
                // margin: 30px auto 0;
            }

            @include lg {
                .pricing-item__description,
                .pricing-item__features {
                    max-width: 900px;
                }
            }

            .pricing-item__action {
                justify-content: flex-start;
                gap: 10px;

                .button {
                    width: fit-content;
                }
            }
            .pricing-item__features {
                @include md {
                    ul {
                        column-count: 2;
                        li {
                            break-inside: avoid;
                        }
                    }
                }
                @include lg {
                    ul {
                        // column-count: 3;
                    }
                }
            }
        }

        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            margin: -1px; /* !importanté */
            border-radius: inherit; /* !importanté */
            background: radial-gradient(rgb(174, 174, 174), rgb(40, 40, 40));
        }

        &__headline {
            display: flex;
            flex-direction: column-reverse;
            align-items: start;
            gap: 10px;

            span {
                font-size: 12px;
                width: fit-content;
                background-color: #333;
                color: #fff;
                padding: 5px 10px;
                border-radius: 5px;
                border: 1px solid transparent;

                &.available {
                    background-color: #444;
                    border-color: #555;
                }

                &.name {
                    background-color: transparent;
                    padding: 0;
                    @include font-size(19px);
                    font-weight: bold;
                }
            }
        }

        &__description {
            display: flex;
            flex-direction: column;
            gap: 10px;
            p {
                margin: 0;
                &.small {
                    color: var(--color-greydark);
                }
            }
        }

        &__price {
            .title-gradient {
                line-height: 1.1;
                font-size: 40px;
                font-weight: bold;
                letter-spacing: calculateRem(-2px);

                .starting {
                    @include font-size(13px);
                    letter-spacing: 0;
                    font-weight: normal;
                    display: block;
                }

                @include lg {
                    font-size: 45px;
                }
            }
            span:not(.title-gradient) {
                color: var(--color-greydark);
            }
        }
        &__action {
            display: flex;
            justify-content: center;
            align-items: center;

            .button {
                width: 100%;
                text-align: center;
                &--bordered {
                    padding: 10px 22px 8px;
                }
            }
        }
        &__features {
            ul {
                margin: 0;
                padding: 0;
                list-style: none;

                li {
                    padding: 5px 0;
                    display: flex;
                    gap: 5px;
                    font-size: calculateRem(14px);
                    color: #ddd;
                    // color: #aaa;
                    // &.white {
                    //     color: #fff;
                    // }

                    svg {
                        color: var(--color-yellow);
                        width: 20px;
                    }
                }
            }
        }
        &__people {
            display: flex;
            flex-direction: column;
            gap: 10px;

            padding: 20px;
            border-radius: 10px;
            border: 1px solid #444;

            .people-name {
                display: flex;
                align-items: center;
                gap: 10px;

                .name {
                    font-weight: bold;
                    display: block;
                    font-size: calculateRem(14px);
                }
                .company {
                    color: var(--color-greydark);
                    font-size: calculateRem(14px);
                }

                img {
                    width: 100%;
                    max-width: 50px;
                    border-radius: 50%;
                }
            }
            .people-quote {
                font-size: calculateRem(14px);
                color: white;
                blockquote {
                    margin: 0;
                    padding: 0;
                    font-weight: normal;
                }
            }
        }
    }

    .small.terms {
        font-size: calculateRem(14px);
        color: var(--color-greydark);
        margin-top: 20px;
        max-width: 850px;
    }
    .cta {
        flex-direction: column;
        align-items: center;
        text-align: center;
        justify-content: center;
    }

    .secured-payment {
        display: flex;
        border-top: 1px solid #444;
        gap: 10px;
        padding-top: 20px;

        table {
            zoom: 0.85;
        }
        span {
            color: var(--color-greydark);
            font-size: calculateRem(13px);
        }
    }
}
