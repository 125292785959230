html {
    font-size: 14px;
    width: 100%;
    height: 100%;
    scroll-behavior: smooth;

    @media (min-width: 992px) {
        font-size: 15px;
    }
    @media (min-width: 1200px) {
        font-size: 16px;
    }
}

body {
    font-family: 'Inter', serif;
    background-color: #010101;
    color: white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@mixin titulos {
    font-weight: 700;
    letter-spacing: calculateRem(-1.5px);
}

h1,
h2,
h3,
h4,
h5,
h6 {
    @include titulos;
}

h4,
h5,
h6 {
    letter-spacing: 0;
}

b,
strong {
    font-weight: 700;
}

.lead {
    font-weight: normal;
}

@for $i from 1 through 4 {
    h#{$i} {
        font-size: var(--font-size-title-h#{$i});
    }
}
