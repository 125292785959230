.accordion-button::after {
    background-image: url(./x.svg);
    transform: rotate(45deg);
}

.accordion-button:not(.collapsed)::after {
    background-image: url(./x-dark.svg);
}

.accordion-button:not(.collapsed)::after {
    /* background-image: url(./x.svg); */
}