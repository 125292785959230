// Image Comparison Slider

$brand-blue-core: #0091df;
$brand-blue-mid: #00617f;

$base-light: #fff;
$base-dark: #111;
$base-pale: #f2f2f2;

.image-comparison {
    max-width: 48.063em;
    margin-right: auto;
    margin-left: auto;

    border-radius: 12px;
    overflow: hidden;
}

.image-comparison__slider-wrapper {
    position: relative;
}

.image-comparison__label {
    font-size: 0;
    line-height: 0;
}

.image-comparison__label,
.image-comparison__range {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background-color: transparent;
    border: none;
    appearance: none;
    outline: none;
    cursor: ew-resize;
    z-index: 20;
}

.image-comparison__range {
    @media (hover) {
        &:hover ~ .image-comparison__slider .image-comparison__thumb {
            transform: scale(1.2);
        }
    }
}

// Specificity needed to override hover state above
.image-comparison .image-comparison__slider-wrapper .image-comparison__range:active,
.image-comparison .image-comparison__slider-wrapper .image-comparison__range:focus,
.image-comparison .image-comparison__slider-wrapper .image-comparison__range--active {
    ~ .image-comparison__slider .image-comparison__thumb {
        transform: scale(0.8);
        background-color: rgba($brand-blue-mid, 0.5);
    }
}

.image-comparison__image-wrapper--overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(50% + 1px);
    height: 100%;
    overflow: hidden;
}

.image-comparison__figure {
    margin: 0;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $base-pale;
    }

    &:not(&--overlay) {
        position: relative;
        padding-top: 66.666666667%;
    }
}

.image-comparison__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 0 50%;
    overflow: hidden;

    .image-comparison__figure--overlay & {
        z-index: 1;
    }
}

.image-comparison__caption {
    position: absolute;
    bottom: 12px;
    min-width: max-content;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    color: $base-light;
    font-weight: bold;
    text-transform: uppercase;

    @media screen and (max-width: 40.063em) {
        font-size: 12px;
    }
}

.image-comparison__caption--before {
    left: 12px;
    z-index: 2;
}

.image-comparison__caption--after {
    right: 12px;
    text-align: right;
}

.image-comparison__caption-body {
    max-width: 40vmin;
    padding: 8px 15px;
    background-color: rgba($base-dark, 1);
    border-radius: 100px;
    font-size: 14px;
}

.image-comparison__slider {
    position: absolute;
    top: 0;
    left: 50%;
    width: 2px;
    height: 100%;
    background-color: $base-light;
    transition: background-color 0.3s ease-in-out;
    z-index: 10;

    .image-comparison__range--active ~ & {
        background-color: rgba($base-light, 0);
    }
}

.image-comparison__thumb {
    position: absolute;
    top: calc(50% - 20px);
    left: calc(50% - 20px);
    width: 40px;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $brand-blue-core;
    color: $base-light;
    border-radius: 50%;
    box-shadow: 0 0 22px 0 rgba($base-dark, 0.5);
    transform-origin: center;
    transition:
        transform 0.3s ease-in-out,
        background-color 0.3s ease-in-out;
}

.image-comparison__range::-webkit-slider-runnable-track {
    width: 40px;
    height: 40px;
    opacity: 0;
}

.image-comparison__range::-moz-range-thumb {
    width: 40px;
    height: 40px;
    opacity: 0;
}

.image-comparison__range::-webkit-slider-thumb {
    width: 40px;
    height: 40px;
    opacity: 0;
}

.image-comparison__range::-ms-fill-lower {
    background-color: transparent;
}

.image-comparison__range::-ms-track {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
    margin: 0;
    padding: 0;
    background-color: transparent;
    color: transparent;
    outline: none;
    cursor: col-resize;
}

.image-comparison__range::-ms-thumb {
    width: 0.5%;
    height: 100%;
    opacity: 0;
}

.image-comparison__range::-ms-tooltip {
    display: none;
}
