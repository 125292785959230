.supported-by {
    display: flex;
    gap: 15px;
    align-items: center;
    -webkit-backdrop-filter: blur(7px);
    backdrop-filter: blur(7px);
    background: rgba(255, 255, 255, .2);
    padding: 8px 30px 4px 15px;
    border-radius: 100px;
    border: 1px solid rgba(255, 255, 255, .12);

    width: fit-content;
    margin-bottom: 30px;

}

.supported-by .people {
    width: 100%;
    max-width: 130px;
}

.supported-by .stars {
    display: flex;
    flex-direction: column;
    gap: 0;
}

.supported-by .stars svg {
    fill: var(--color-yellow);
}

.supported-by .stars-top {
    display: flex;
    gap: 5px;
}

.supported-by .stars-bottom {
    line-height: 1;
}

.supported-by .stars-bottom span {
    text-align: left;
    font-size: 12px;
    line-height: 1.1;
}

.supported-by .stars-bottom .color {
    font-weight: bold;
    display: block;
}