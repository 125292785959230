.lead-capture-form {
    --spacing: 30px;
    --border-radius: 12px;
    background: linear-gradient(20deg, rgb(3, 3, 3) 57%, rgb(47, 47, 47) 100%);
    position: relative;
    padding: 20px;
    border-radius: var(--border-radius);
    border: 1px solid rgba(255, 255, 255, 0.15);

    h1 {
        @include font-size(20px);
        color: #777;
        letter-spacing: 0;
    }

    .form-stage {
        h2 {
            margin-bottom: 30px;
            font-size: var(--font-size-title-h3);
        }
    }

    .form-field {
        margin-bottom: 10px;
    }

    .website-type-selector {
        display: flex;
        gap: 12px;
        padding: 5px 0;

        button {
            border: 0;
            background-color: rgba(255, 255, 255, 0.1);
            padding: 10px;
            border-radius: calc(var(--border-radius) / 2);
            color: white;
            border: 1px solid transparent;
            display: flex;
            flex-direction: column;
            align-items: center;
            transition: all 0.3s ease;
            flex: 1;
            min-width: 0;
            width: 100%;

            span {
                display: block;
                width: 100%;
                text-align: center;
                @include font-size(12px);
            }

            &.selected {
                // background-color: rgba(255, 255, 255, 0.2);
                // border-color: rgba(255, 255, 255, 0.3);
                background-color: var(--color-yellow);
                color: #000;
                filter: drop-shadow(0 10px 30px rgba(215, 255, 59, 0.5));
            }
            &.pre-selected {
                background-color: var(--color-yellow);
                color: #000;
                &:hover {
                    background-color: var(--color-yellow);
                    color: #000;
                    filter: drop-shadow(0 10px 30px rgba(215, 255, 59, 0.5));
                }
            }
            &:hover {
                // background-color: rgba(255, 255, 255, 0.2);
            }

            .website-type-icon {
                @include font-size(30px);
            }
        }

        &:has(button.pre-selected) button:not(.pre-selected) {
            opacity: 0.5;
            cursor: not-allowed;
        }
    }

    label {
        display: block;
        margin-bottom: 4px;
        color: #555;
        font-weight: 500;
    }

    %form-input {
        width: 100%;
        padding: 10px;
        border: 1px solid rgba(255, 255, 255, 0.2);
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 4px;
        color: white;
        @include font-size(14px);
        transition: border-color 0.3s ease;

        &:focus {
            outline: none;
            border-color: #4caf50;
            box-shadow: 0 0 0 2px rgba(76, 175, 80, 0.1);
        }
    }

    input[type='text'],
    input[type='email'],
    textarea,
    select {
        @extend %form-input;
    }

    textarea {
        min-height: 100px;
        resize: vertical;
    }

    textarea,
    input {
        &::placeholder {
            color: #999;
        }
    }

    select {
        height: 42px;
        option {
            color: black;
        }
    }

    .checkbox {
        &-group {
            display: flex;
            flex-wrap: wrap;
            gap: 12px;
            padding: 5px 0;
        }

        &-label {
            display: flex;
            align-items: center;
            cursor: pointer;
            padding: 5px;
            @include font-size(13px);
            color: white;
            border-radius: 4px;
            transition: background-color 0.2s ease;
            background-color: rgba(255, 255, 255, 0.1);

            input {
                margin-right: 5px;
            }
        }
    }

    .navigation-buttons {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        padding-top: 10px;
        border-top: 2px solid rgba(255, 255, 255, 0.1);
    }
    /* Progress bar styles */
    .progress-bar-container {
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        gap: 10px;

        background-color: rgba(255, 255, 255, 0.1);
        padding: 2px 10px;
        border-radius: 100px;
        margin-bottom: 10px;
    }

    .progress-bar {
        height: 6px;
        background-color: var(--color-yellow);
        filter: drop-shadow(0 17px 50px rgba(215, 255, 59, 0.4));
        border-radius: 10px;
        transition: width 0.3s ease;
    }

    .progress-bar-wrapper {
        width: calc(100% - 125px);
    }

    .progress-text {
        color: white;
        @include font-size(10px);
        width: 125px;
        font-weight: bold;
    }

    .error-message {
        color: #ff6b6b;
        font-size: 12px;
        margin-top: 4px;
        font-weight: 500;
    }

    input.error,
    textarea.error,
    select.error {
        border-color: #ff4444;
    }

    .success-message {
        text-align: center;
        padding: 40px 20px;

        .success-icon {
            width: 60px;
            height: 60px;
            background-color: var(--color-yellow);
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto 20px;
            font-size: 30px;
            color: #000;
            filter: drop-shadow(0 17px 50px rgba(215, 255, 59, 0.4));
        }

        h2 {
            color: var(--color-yellow);
            margin-bottom: 15px;
            font-size: var(--font-size-title-h3);
        }

        p {
            color: #fff;
            opacity: 0.8;
            max-width: 400px;
            margin: 0 auto;
            line-height: 1.6;
        }
    }

    input:focus,
    textarea:focus,
    select:focus {
        outline: 2px solid var(--color-yellow);
        outline-offset: 2px;
    }

    input[aria-invalid='true'],
    textarea[aria-invalid='true'],
    select[aria-invalid='true'] {
        border-color: #ff4444;
    }
}
