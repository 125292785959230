.web {
    @include md {
        --container-width: 1000px;
    }

    .title {
        .subtitle {
            text-transform: uppercase;
            font-weight: bold;
            letter-spacing: calculateRem(2px);
        }
        &-gradient.title-h1 {
            font-size: var(--font-size-title-h1);
        }
    }

    .person-img {
        width: 35px;
    }

    .projects,
    .fix,
    .info,
    .data,
    .pricing {
        padding: 50px 0;
    }

    .about-us {
        background-position: center top;
        min-height: 85vh;

        .person-img {
            width: 40px;
            @include md {
                width: 70px;
            }
        }
        .h-title {
            line-height: 1.2;
            text-align: left;
        }
        .title {
            align-items: flex-start;
            @include md {
                max-width: 100%;
            }
        }
        .hero-container {
            align-items: center;
        }
        .actions {
            display: flex;
            align-content: center;
            gap: 20px;
        }
        &:before {
            display: none;
        }
        &__description {
            text-align: left;
        }
    }

    .logos-section {
        padding-top: 10px;
    }

    .compare-section {
        padding-bottom: 100px;

        .container {
            &.info {
                padding-bottom: 0;
            }
        }

        .compare {
            outline: 2px solid #444;
            border-radius: var(--border-radius-large);
            padding: 50px 20px 20px;
            position: relative;
            background: radial-gradient(
                    100.56% 112.16% at 98.17% -25.21%,
                    rgba(126, 133, 137, 0.24) 0%,
                    rgba(0, 0, 0, 0) 100%
                ),
                rgba(30, 35, 42, 0.81);

            &-title {
                position: absolute;
                top: -22px;
                left: 0;
                right: 0;
                width: fit-content;
                margin: auto;
                background-color: #444;
                padding: 10px 20px;
                border-radius: 100px;
                font-weight: bold;
            }

            ul {
                display: flex;
                flex-direction: column;
                gap: 10px;
                padding-top: 20px;

                li {
                    font-size: var(--font-size-text-large);
                }
            }

            img {
                display: block;
                margin: auto;
            }

            &.good {
                outline-color: var(--color-yellow);
                background: radial-gradient(
                        100.56% 112.16% at 98.17% -25.21%,
                        rgba(211, 222, 116, 0.24) 0%,
                        rgba(0, 0, 0, 0) 100%
                    ),
                    rgba(30, 35, 42, 0.81);

                .compare-title {
                    background-color: var(--color-yellow);
                    color: black;
                }
            }
        }
    }

    .info,
    .projects {
        .title-gradient {
            font-size: var(--font-size-title-h1);
        }
    }

    .info {
        padding-top: 100px;
    }

    .projects__grid .text .info .button {
        width: fit-content;
    }

    .projects .project-item {
        .services,
        .info .tags {
            display: none;
        }
    }

    .projects__grid .text .info,
    .projects .project-item .info {
        padding: 0;
    }

    .fix {
        & > .container {
            @include lg {
                margin: auto;
                max-width: var(--container-width);
            }

            &.info {
                text-align: center;
                .title {
                    width: 100%;
                    align-items: center;
                }
                img {
                    margin-top: 50px;
                }
                span.color-yellow {
                    display: inline;
                }
            }
        }

        .title {
            width: 100%;
            max-width: 100%;
            align-items: center;
        }

        .slide-content figure {
            aspect-ratio: 4/4.2;
        }

        .before-after {
            &-desktop {
                display: none;
                @include md {
                    display: block;
                }
            }
            &-mobile {
                display: block;
                max-width: 80%;
                margin: auto;

                @include md {
                    display: none;
                }
            }
        }

        .cards {
            --spacing: 30px;

            .info-card {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: var(--spacing);
                margin-bottom: var(--spacing);
                padding: var(--spacing);
                border-width: 2px;

                @include md {
                    flex-direction: row;
                }

                figure {
                    border-radius: var(--border-radius-large);
                }

                figure,
                & > div {
                    flex: 1;
                    padding: 0;
                }

                p {
                    margin-bottom: 1rem;
                    &:last-of-type {
                        margin: 0;
                    }
                }

                &.even {
                    @include md {
                        flex-direction: row-reverse;
                    }
                }

                &__content {
                    gap: var(--spacing);
                    h3 {
                        font-size: var(--font-size-title-h2);
                        letter-spacing: calculateRem(-0.75px);
                        position: relative;
                        width: fit-content;
                        margin-bottom: 20px;

                        &::before {
                            content: '';
                            position: absolute;
                            bottom: -15px;
                            left: 0;
                            width: 40px;
                            height: 3px;
                            background-color: var(--color-yellow);
                        }
                    }
                }
            }
        }
    }

    .data {
        &-row {
            .col-12 {
                .data__item {
                    margin-bottom: 30px;
                }
                &:last-of-type .data__item {
                    margin-bottom: 0;
                }
            }
        }

        .title {
            text-align: center;
            margin-bottom: 50px;
            .title-gradient {
                font-size: var(--font-size-title-h1);
            }
        }

        &__item {
            border: 1px solid #384d59;
            background: radial-gradient(
                    100.56% 112.16% at 98.17% -25.21%,
                    rgba(126, 133, 137, 0.24) 0%,
                    rgba(0, 0, 0, 0) 100%
                ),
                rgba(30, 35, 42, 0.81);
            border-radius: 12px;
            padding: 30px;
            position: relative;
            overflow: hidden;
            min-height: 250px;

            h3,
            p {
                position: relative;
                z-index: 1;
            }

            h3 {
                font-size: var(--font-size-title-h1);
            }

            p {
                font-size: var(--font-size-text-small);
            }

            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-image: radial-gradient(circle, var(--color2, #e16e6c) 60%, #1e2229 50%);
                background-size: 150%;
                background-position: 102px 58px;
                background-repeat: no-repeat;
                filter: blur(40px);
                opacity: 0.65;
            }
        }

        .col-12:nth-child(2) .data__item:after,
        .col-12:nth-child(4) .data__item:after {
            --color2: #ac6ce1;
        }

        .col-12:nth-child(3) .data__item:after,
        .col-12:nth-child(5) .data__item:after {
            --color2: #0e8621;
        }
    }

    .pricing {
        .pricing__footer {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 20px;
            max-width: 600px;
            margin: 0 auto;

            .small {
                text-align: center;
                color: var(--color-greydark);
                font-size: var(--font-size-text-small);

                a {
                    color: var(--color-yellow);
                    display: block;
                }
            }
        }

        .title {
            margin: 0 auto 30px;
            text-align: center;

            .title-gradient {
                font-size: var(--font-size-title-h1);
                margin-bottom: 20px;
            }
        }

        .price {
            gap: 10px;

            span {
                line-height: 1;
            }

            .old {
                color: var(--color-greydark);
                text-decoration: line-through;
                font-size: var(--font-size-title-h2);
            }

            .new {
                color: var(--color-yellow);
                font-weight: bold;
                text-shadow: 0 0 50px rgba(255, 255, 0, 0.86);
                font-size: var(--font-size-title-display);
            }
        }

        .table {
            max-width: 500px;
            margin: 50px auto;

            ul {
                list-style: none;
                border: 1px solid #384d59;
                background: radial-gradient(
                        100.56% 112.16% at 98.17% -25.21%,
                        rgba(126, 133, 137, 0.24) 0%,
                        rgba(0, 0, 0, 0) 100%
                    ),
                    rgba(30, 35, 42, 0.81);
                border-radius: 12px;
                padding: 15px;
                position: relative;
                margin: 0 auto 20px;

                li {
                    font-weight: normal;
                    padding: 8px 0;
                    border-bottom: 1px solid rgba(255, 255, 255, 0.15);

                    &.h {
                        color: var(--color-yellow);
                        font-weight: bold;
                    }

                    &:last-child {
                        border-bottom: none;
                    }
                }
            }
        }

        .payments {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            gap: 20px;
        }
    }

    .faqs .title .title-gradient,
    .warranty .title-gradient {
        font-size: var(--font-size-title-h1);
    }

    .faqs {
        .accordion-body .highlight {
            color: var(--color-yellow);
        }
        .title-gradient .color-yellow {
            display: block;
        }
    }

    .warranty {
        .title {
            text-align: center;
            margin: auto;
        }

        img {
            display: block;
            width: 100%;
            max-width: 500px;
            margin: auto;
            height: auto;
        }

        .lead,
        .small {
            font-size: var(--font-size-text-large);
            color: var(--color-greydark);
            text-align: center;
        }

        .small {
            font-size: var(--font-size-text-small);
        }

        &__content {
            border: 1px solid #384d59;
            background: radial-gradient(
                    100.56% 112.16% at 98.17% -25.21%,
                    rgba(126, 133, 137, 0.24) 0%,
                    rgba(0, 0, 0, 0) 100%
                ),
                rgba(30, 35, 42, 0.81);
            border-radius: 12px;
            padding: 30px 15px;
            position: relative;
        }
    }

    .call .actions {
        flex-direction: column;

        .small {
            font-size: var(--font-size-text-normal);
            margin: 0;
            order: 2;
            text-align: center;

            strong {
                font-size: var(--font-size-text-large);
                display: block;
            }
        }

        .button {
            order: 3;
            max-width: fit-content;
        }

        .supported-by {
            order: 1;
        }
    }
}
